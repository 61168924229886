import {
  createContext,
  Reducer,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from "react";
import * as subsApi from "lib/api/subscription";
import analytics from "analytics";
import useResponseParser, {
  MakeHandlerArgsFromRequestFn,
} from "utils/hooks/useResponseParser";
import { getCheckoutSession, updateExistingPlan } from "lib/api/checkout";
import { AccountContext } from "contexts/AccountProvider";
import {
  ActiveBoxDetailsWithTrackingHistory,
  SnakeToCamelCaseNested,
  SubscriptionDetailsSchema,
  SubscriptionSchema,
  SubscriptionWithShipmentDetailsSchema,
} from "@literati/kids-api";
import {
  AddAffiliatePayload,
  ClubSchemaCamelCase,
  ExtendReturnWindowPayload,
  GetCompleteSubscriptionPayload,
  GetOrderSummaryPayload,
  GetPastBoxesPayload,
  GetSubscriptionPayload,
  PastBoxesResponseCamelCase,
  RedeemRetentionOfferPayload,
  RemoveAffiliatePayload,
  SaveAddressPayload,
  SubscriptionPlanCamelCase,
  UpdateChildInfoPayload,
  UpdateDeliveryTimelinePayload,
  UpdateDetailsDetailsPayload,
  UpdateFulfillmentGroupPayload,
} from "lib/api/subscription";
import { ReactNode } from "react";
import { isAPIError } from "lib/api/base";

export type SubscriptionSchemaCamelCase =
  SnakeToCamelCaseNested<SubscriptionSchema>;

export type SubscriptionDetailsSchemaCamelCase =
  SnakeToCamelCaseNested<SubscriptionDetailsSchema>;

export type ActiveBoxDetailsWithTrackingHistoryCamelCase =
  SnakeToCamelCaseNested<ActiveBoxDetailsWithTrackingHistory>;

export type SubscriptionWithShipmentDetailsSchemaCamelCase =
  SnakeToCamelCaseNested<SubscriptionWithShipmentDetailsSchema>;

export type SubscriptionsProviderSubscriptions =
  | (SubscriptionSchemaCamelCase | SubscriptionDetailsSchemaCamelCase)[];

export type SubscriptionsProviderSubscription =
  | SubscriptionDetailsSchemaCamelCase
  | SubscriptionWithShipmentDetailsSchemaCamelCase;

export type ConfigCancellationArgs = {
  subId: number;
  cancelCategory: string;
  nextShipmentCancellable: boolean;
  onSuccess: OnSuccessFn;
} & MakeHandlerArgsFromRequestFn<typeof subsApi.confirmCancellation>;

export type UpdateChildInfoArgs = {
  details: UpdateChildInfoPayload;
} & MakeHandlerArgsFromRequestFn<typeof subsApi.updateChildInfo> & {
    onSuccess: OnSuccessFn;
  };

export type UpdateDeliveryFrequencyArgs = UpdateDeliveryTimelinePayload &
  MakeHandlerArgsFromRequestFn<typeof subsApi.updateDeliveryTimeline> & {
    onSuccess: OnSuccessFn;
  };

export type UpdateDetailsArgs = {
  subId: number;
  details: UpdateDetailsDetailsPayload;
} & MakeHandlerArgsFromRequestFn<typeof subsApi.updateDetails> & {
    onSuccess: OnSuccessFn;
  };

export type ExtendReturnWindowArgs = ExtendReturnWindowPayload &
  MakeHandlerArgsFromRequestFn<typeof subsApi.extendReturnWindow> & {
    onSuccess: OnSuccessFn;
  };

export type GetOrderSummaryArgs = Pick<
  GetOrderSummaryPayload,
  "boxId" | "coupon" | "plan"
> &
  MakeHandlerArgsFromRequestFn<typeof subsApi.getOrderSummary>;

export type ReactivateSubscriptionArgs = {
  plan: string;
  onSuccess: OnSuccessFn;
  successMessage?: string;
};

export type UpdateSubscriptionPlanArgs = {
  selectedPlan: string;
  subId: number;
  onSuccess: OnSuccessFn;
} & MakeHandlerArgsFromRequestFn<typeof updateExistingPlan>;

export type GetAffiliatesArgs = {
  subId: number;
  page?: number;
} & MakeHandlerArgsFromRequestFn<typeof subsApi.getAffiliates>;

export type AddAffiliateArgs = AddAffiliatePayload &
  MakeHandlerArgsFromRequestFn<typeof subsApi.addAffiliate>;

export type RemoveAffiliateArgs = RemoveAffiliatePayload &
  MakeHandlerArgsFromRequestFn<typeof subsApi.removeAffiliate>;

export type RedeemRedemptionOfferArgs = RedeemRetentionOfferPayload &
  MakeHandlerArgsFromRequestFn<typeof subsApi.redeemRetentionOffer>;

export type UpdateFulfillmentGroupArgs = UpdateFulfillmentGroupPayload &
  MakeHandlerArgsFromRequestFn<typeof subsApi.updateFulfillmentGroup>;

export interface SubscriptionsContextType {
  subscriptions: SubscriptionsProviderSubscriptions | null;
  subscription: SubscriptionsProviderSubscription | null;
  activeBox: ActiveBoxDetailsWithTrackingHistoryCamelCase | null;
  pastBoxes: PastBoxesResponseCamelCase | null;
  subscriptionPlans: SubscriptionPlanCamelCase[] | null;
  clubs: ClubSchemaCamelCase[] | null;
  selectedPlan: string | null;
  retentionOffers: unknown;
  getPastBoxes?: typeof subsApi.getPastBoxes;
  attemptCancellation?: typeof subsApi.attemptCancellation;
  confirmCancellation?: (
    args: ConfigCancellationArgs
  ) => ReturnType<typeof subsApi.confirmCancellation>;
  updateChildInfo?: (
    args: UpdateChildInfoArgs
  ) => ReturnType<typeof subsApi.updateChildInfo>;
  updateDeliveryFrequency?: (
    args: UpdateDeliveryFrequencyArgs
  ) => ReturnType<typeof subsApi.updateDeliveryTimeline>;
  updateDetails?: (
    args: UpdateDetailsArgs
  ) => ReturnType<typeof subsApi.updateDetails>;
  saveShippingAddress?: typeof subsApi.saveAddress;
  extendReturnWindow?: (
    args: ExtendReturnWindowArgs
  ) => ReturnType<typeof subsApi.extendReturnWindow>;
  hasNonGiftSub: boolean;
  getOrderSummary?: (
    args: GetOrderSummaryArgs
  ) => ReturnType<typeof subsApi.getOrderSummary>;
  reactivateSubscription?: (
    args: ReactivateSubscriptionArgs
  ) => ReturnType<typeof subsApi.reactivateSubscription>;
  updateSubscriptionPlan?: (
    args: UpdateSubscriptionPlanArgs
  ) => ReturnType<typeof updateExistingPlan>;
  getAffiliates?: (
    args: GetAffiliatesArgs
  ) => ReturnType<typeof subsApi.getAffiliates>;
  addAffiliate?: (
    args: AddAffiliateArgs
  ) => ReturnType<typeof subsApi.addAffiliate>;
  removeAffiliate?: (
    args: RemoveAffiliateArgs
  ) => ReturnType<typeof subsApi.removeAffiliate>;
  redeemRetentionOffer?: (
    args: RedeemRedemptionOfferArgs
  ) => ReturnType<typeof subsApi.redeemRetentionOffer>;
  updateFulfillmentGroup?: (
    args: UpdateFulfillmentGroupArgs
  ) => ReturnType<typeof subsApi.updateFulfillmentGroup>;
  isOneWayPlan?: boolean;
  isOneWayBox?: boolean;
}

const initialState: SubscriptionsContextType = {
  subscriptions: null,
  subscription: null,
  activeBox: null,
  pastBoxes: null,
  subscriptionPlans: null,
  clubs: null,
  selectedPlan: null,
  retentionOffers: null,
  hasNonGiftSub: false,
  isOneWayBox: false,
  isOneWayPlan: false,
};

export const SubscriptionsContext =
  createContext<SubscriptionsContextType>(initialState);

/** @todo - update this type as other files are converted over to TypeScript */
export type SubscriptionsServerState = Partial<SubscriptionsContextType>;

export type GetResourcesArgs = {
  // because these handlers are shared between multiple functions,
  // they cannot expect to receive a particular response as their argument
  onSuccess?: () => Promise<void> | void;
  onError?: () => Promise<void> | void;
};

export type GetSubscriptionBasicsArgs = {
  subId: GetSubscriptionPayload["id"];
  token: GetSubscriptionPayload["token"];
};

export type GetSubscriptionPlansArgs = MakeHandlerArgsFromRequestFn<
  typeof subsApi.getSubscriptionPlans
>;

export interface SubscriptionsDispatchContextType {
  setStateFromServerProps: (_: SubscriptionsServerState) => void;
  getResources?: (handlers: GetResourcesArgs) => Promise<void>;
  getSubscription?: (
    args: GetCompleteSubscriptionPayload
  ) => ReturnType<typeof subsApi.getCompleteSubscription>;
  getSubscriptionBasics?: (
    args: GetSubscriptionBasicsArgs
  ) => ReturnType<typeof subsApi.getSubscription>;
  getSubscriptions?: () => Promise<void>;
  getSubscriptionPlans?: (
    handlers: GetSubscriptionPlansArgs
  ) => ReturnType<typeof subsApi.getSubscriptionPlans>;
  getActiveBox?: () => Promise<
    undefined | Awaited<ReturnType<typeof subsApi.getActiveBoxDetails>>
  >;
  hasNonGiftSub: boolean;
}

export const SubscriptionsDispatchContext =
  createContext<SubscriptionsDispatchContextType>({
    setStateFromServerProps: (_serverState) => false,
    hasNonGiftSub: Boolean(),
  });

export interface SubscriptionProviderReducerActionMap {
  SET_SUBSCRIPTION_PLANS: SubscriptionPlanCamelCase[];
  SET_CLUBS: ClubSchemaCamelCase[];
  UPDATE_SUBSCRIPTIONS_LIST: SubscriptionSchemaCamelCase[];
  UPDATE_SUBSCRIPTION:
    | SubscriptionDetailsSchemaCamelCase
    | SubscriptionsContextType;
  UPDATE_ACTIVE_BOX: ActiveBoxDetailsWithTrackingHistoryCamelCase | null;
  UPDATE_STATE_FROM_SERVER_PROPS: SubscriptionsServerState;
}

export type SubscriptionProviderReducerActionType =
  keyof SubscriptionProviderReducerActionMap;

export type SubscriptionProviderReducerPayload =
  SubscriptionProviderReducerActionMap[SubscriptionProviderReducerActionType];

export type SubscriptionProviderReducerAction = {
  type: SubscriptionProviderReducerActionType;
  payload: SubscriptionProviderReducerPayload;
};

const reducer: Reducer<
  SubscriptionsContextType,
  SubscriptionProviderReducerAction
> = (state, action) => {
  const updateInList = (subscription: SubscriptionDetailsSchemaCamelCase) => {
    const subs = state.subscriptions;
    const updatedList = subs ? [...subs] : [];
    const index = updatedList.findIndex((s) => s.id === subscription.id);
    updatedList[index] = subscription;
    return { ...state, subscription, subscriptions: updatedList };
  };

  switch (action.type) {
    case "SET_SUBSCRIPTION_PLANS":
      return {
        ...state,
        subscriptionPlans: action.payload as SubscriptionPlanCamelCase[],
      };
    case "SET_CLUBS":
      return { ...state, clubs: action.payload as ClubSchemaCamelCase[] };
    case "UPDATE_SUBSCRIPTIONS_LIST":
      return {
        ...state,
        subscriptions: action.payload as SubscriptionSchemaCamelCase[],
      };
    case "UPDATE_SUBSCRIPTION":
      return updateInList(action.payload as SubscriptionDetailsSchemaCamelCase);
    case "UPDATE_ACTIVE_BOX":
      return {
        ...state,
        activeBox:
          action.payload as ActiveBoxDetailsWithTrackingHistoryCamelCase,
      };
    /** @deprecated - this switch statement appears to be unused */
    case "UPDATE_STATE_FROM_SERVER_PROPS":
      return { ...state, ...action.payload };
    default:
      throw new Error();
  }
};

type OnSuccessFn = () => Promise<void> | void;

export interface SubscriptionsProviderProps {
  children: ReactNode;
}

export const SubscriptionsProvider = ({
  children,
}: SubscriptionsProviderProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  /** @todo - remove `as any` once `AccountContext` is typed */
  const { refreshPaymentMethods, user } = useContext(AccountContext) as any;
  const userId = user?.id;

  const parseResponse = useResponseParser();

  const getSubscriptions = async () => {
    const subscriptions = await subsApi.getSubscriptions();
    if (!isAPIError(subscriptions)) {
      dispatch({
        type: "UPDATE_SUBSCRIPTIONS_LIST",
        payload: subscriptions,
      });
    }
  };

  useEffect(() => {
    if (userId) {
      getSubscriptions();
    }
  }, [userId]);

  const setStateFromServerProps = (serverState: SubscriptionsServerState) => {
    dispatch({
      type: "UPDATE_STATE_FROM_SERVER_PROPS",
      payload: serverState,
    });
  };

  const getPastBoxes = async ({ subId }: GetPastBoxesPayload) => {
    const res = await subsApi.getPastBoxes({ subId });
    return parseResponse({ res });
  };

  const updateSubFromResponse = (
    subResponse: SubscriptionDetailsSchemaCamelCase
  ) => {
    /** response may not yet contain all details, so we merge */
    const updated = {
      ...state.subscription,
      ...subResponse,
    };

    dispatch({ type: "UPDATE_SUBSCRIPTION", payload: updated });
    return updated;
  };

  const getSubscription = async ({ subId }: GetCompleteSubscriptionPayload) => {
    const subscription = await subsApi.getCompleteSubscription({ subId });
    if (isAPIError(subscription)) {
      return parseResponse({
        res: subscription,
        errorMessage:
          "There was an error retrieving your subscription. Please try again later.",
      });
    }
    updateSubFromResponse(subscription);
    return subscription;
  };
  const getSubscriptionBasics = async ({
    subId,
    token,
  }: GetSubscriptionBasicsArgs) => {
    const subscription = await subsApi.getSubscription({ id: subId, token });
    if (isAPIError(subscription)) {
      return parseResponse({
        res: subscription,
        errorMessage:
          "There was an error retrieving your subscription. Please try again later.",
      });
    }
    updateSubFromResponse(subscription?.subscription);
    return subscription;
  };

  const updateDetails = async ({
    subId,
    details,
    onSuccess,
    ...handlers
  }: UpdateDetailsArgs) => {
    const res = await subsApi.updateDetails(subId, details);
    return parseResponse({
      res,
      onSuccess: (res) => {
        onSuccess && onSuccess();
        if (!isAPIError(res)) {
          updateSubFromResponse(res.subscription);
        }
      },
      successMessage: "Updated your member's details",
      ...handlers,
    });
  };

  const updateChildInfo = async ({
    details,
    onSuccess,
    ...handlers
  }: UpdateChildInfoArgs) => {
    const res = await subsApi.updateChildInfo(details);
    return parseResponse({
      res,
      onSuccess: (res) => {
        // Theoretically we should never get here on success
        if ("error" in res) {
          return;
        }
        onSuccess && onSuccess();
        updateSubFromResponse(res.subscription);
      },
      successMessage: "Updated your reader's details",
      ...handlers,
    });
  };

  const updateDeliveryFrequency = async ({
    subId,
    skipDuration,
    deliveryTimeline,
    onSuccess,
    ...handlers
  }: UpdateDeliveryFrequencyArgs) => {
    const res = await subsApi.updateDeliveryTimeline({
      subId,
      skipDuration,
      deliveryTimeline,
    });
    return parseResponse({
      res,
      successMessage: "Updated delivery frequency",
      onSuccess: async () => {
        await getSubscription({ subId });
        onSuccess && onSuccess();
      },
      ...handlers,
    });
  };

  const saveShippingAddress = async ({
    address,
    subId,
  }: SaveAddressPayload) => {
    const res = await subsApi.saveAddress({ address, subId });
    const onSuccess = (
      res: Awaited<ReturnType<typeof subsApi.saveAddress>>
    ) => {
      updateSubFromResponse(res.subscription);
    };
    return parseResponse({
      res,
      onSuccess,
      successMessage: "Updated shipping address.",
    });
  };

  const getActiveBox = async () => {
    if (!(state.subscription as SubscriptionDetailsSchemaCamelCase)?.id) {
      return;
    }
    const res = await subsApi.getActiveBoxDetails({
      subId: (state?.subscription as SubscriptionDetailsSchemaCamelCase)?.id,
    });
    return parseResponse({
      res,
      errorMessage:
        "There was an error updating your current box. Please try again.",
      onSuccess: () =>
        dispatch({
          type: "UPDATE_ACTIVE_BOX",
          payload: "error" in res ? null : res,
        }),
    });
  };

  const extendReturnWindow = async ({
    boxId,
    days,
    offerId,
    onSuccess,
    ...handlers
  }: ExtendReturnWindowArgs) => {
    const res = await subsApi.extendReturnWindow({ boxId, days, offerId });
    return parseResponse({
      res,
      errorMessage:
        "There was an error processing your extension. Please try again or contact customer support.",
      successMessage: "Your return window has been extended.",
      onSuccess: async () => {
        await getActiveBox();
        onSuccess && (await onSuccess());
      },
      ...handlers,
    });
  };

  const getOrderSummary = async ({
    boxId,
    coupon,
    plan,
    onError,
  }: GetOrderSummaryArgs) => {
    const res = await subsApi.getOrderSummary({
      boxId,
      coupon,
      plan,
      subscriptionId: (state.subscription as SubscriptionDetailsSchemaCamelCase)
        ?.id,
    });
    return parseResponse({ res, onError });
  };

  const getSubscriptionPlans = async (handlers: GetSubscriptionPlansArgs) => {
    const res = await subsApi.getSubscriptionPlans();
    return parseResponse({
      res,
      errorMessage:
        "There was an error fetching available plans. Please refresh and try again.",
      onSuccess: () =>
        dispatch({ type: "SET_SUBSCRIPTION_PLANS", payload: res, ...handlers }),
    });
  };

  const getClubs = async (
    handlers: MakeHandlerArgsFromRequestFn<typeof subsApi.getClubs>
  ) => {
    const res = await subsApi.getClubs();
    return parseResponse({
      res,
      errorMessage:
        "There was an error fetching available clubs. Please refresh and try again.",
      onSuccess: () =>
        dispatch({ type: "SET_CLUBS", payload: res, ...handlers }),
    });
  };

  const getResources = async (handlers: GetResourcesArgs) => {
    await Promise.all([getClubs(handlers), getSubscriptionPlans(handlers)]);
  };

  const hasNonGiftSub = Boolean(
    state.subscriptions?.find((s) => !s?.plan?.slug.startsWith("gift"))
  );

  const reactivateSubscription = async ({
    plan,
    onSuccess,
    successMessage,
  }: ReactivateSubscriptionArgs) => {
    const res = await subsApi.reactivateSubscription({
      subscriptionId: (state.subscription as SubscriptionDetailsSchemaCamelCase)
        .id,
      plan,
    });
    return parseResponse({
      res,
      onSuccess: () => {
        getSubscription({
          subId: (state.subscription as SubscriptionDetailsSchemaCamelCase).id,
        });
        refreshPaymentMethods();
        onSuccess && onSuccess();
      },
      toastOnError: (res as any)?.error?.code !== "payment_invalid",
      successMessage:
        successMessage || "Successfully reactivated your subscription!",
    });
  };

  const updateSubscriptionPlan = async ({
    selectedPlan,
    subId,
    onSuccess,
    ...handlers
  }: UpdateSubscriptionPlanArgs) => {
    // todo: request endpoint that does not depend on session
    await getCheckoutSession();
    const res = await updateExistingPlan({
      plan: selectedPlan,
      subscription: subId,
    });
    return parseResponse({
      res,
      onSuccess: () => {
        getSubscription({ subId });
        refreshPaymentMethods();
        onSuccess && onSuccess();
      },
      successMessage: "Successfully updated your plan",
      ...handlers,
    });
  };

  const getAffiliates = async ({
    subId,
    page,
    ...handlers
  }: GetAffiliatesArgs) => {
    const res = await subsApi.getAffiliates({ subId, page });
    return parseResponse({
      res,
      ...handlers,
    });
  };

  const addAffiliate = async ({
    affiliate,
    subId,
    ...handlers
  }: AddAffiliateArgs) => {
    const res = await subsApi.addAffiliate({ affiliate, subId });
    return parseResponse({
      res,
      ...handlers,
    });
  };

  const removeAffiliate = async ({
    subId,
    affiliateId,
    ...handlers
  }: RemoveAffiliateArgs) => {
    const res = await subsApi.removeAffiliate({ subId, affiliateId });
    return parseResponse({
      res,
      ...handlers,
    });
  };

  const confirmCancellation = async ({
    subId,
    cancelCategory,
    nextShipmentCancellable,
    onSuccess,
    ...handlers
  }: ConfigCancellationArgs) => {
    const res = await subsApi.confirmCancellation(
      { id: subId },
      cancelCategory,
      nextShipmentCancellable
    );
    return parseResponse({
      res,
      successMessage: "Subscription canceled",
      onSuccess: async () => {
        await getSubscriptions();
        onSuccess && onSuccess();
      },
      ...handlers,
    });
  };

  const redeemRetentionOffer = async ({
    subId,
    offerId,
    ...handlers
  }: RedeemRedemptionOfferArgs) => {
    const res = await subsApi.redeemRetentionOffer({ subId, offerId });
    return parseResponse({
      res,
      errorMessage: "Unable to redeem this offer.",
      ...handlers,
    });
  };

  const updateFulfillmentGroup = async ({
    subId,
    payload,
    ...handlers
  }: UpdateFulfillmentGroupArgs) => {
    const res = await subsApi.updateFulfillmentGroup({ subId, payload });
    return parseResponse({
      res,
      ...handlers,
    });
  };

  const value = useMemo(
    () => ({
      ...state,
      getPastBoxes,
      attemptCancellation: subsApi.attemptCancellation,
      confirmCancellation,
      updateChildInfo,
      updateDeliveryFrequency,
      updateDetails,
      saveShippingAddress,
      extendReturnWindow,
      hasNonGiftSub,
      getOrderSummary,
      getSubscriptionPlans,
      reactivateSubscription,
      updateSubscriptionPlan,
      getAffiliates,
      addAffiliate,
      removeAffiliate,
      redeemRetentionOffer,
      updateFulfillmentGroup,
      isOneWayPlan: (state.subscription as SubscriptionDetailsSchemaCamelCase)
        ?.plan?.bookChargesExempt,
      isOneWayBox: !state.activeBox?.boxShipment?.isReturnable,
    }),
    [state]
  );

  /** use for function calls that are made in effects */
  const dispatchValue = useMemo(
    () => ({
      hasNonGiftSub: false,
      setStateFromServerProps,
      getResources,
      getSubscription,
      getSubscriptionBasics,
      getSubscriptions,
      getActiveBox,
    }),
    []
  );

  analytics.userAuthenticated(value);

  return (
    <SubscriptionsDispatchContext.Provider value={dispatchValue}>
      <SubscriptionsContext.Provider value={value}>
        {children}
      </SubscriptionsContext.Provider>
    </SubscriptionsDispatchContext.Provider>
  );
};

export const withSubscriptionsProvider = <P extends JSX.IntrinsicAttributes>(
  Component: (props: P) => JSX.Element
) => {
  return function ComponentWithProvider(props: P) {
    return (
      <SubscriptionsProvider>
        <Component {...props} />
      </SubscriptionsProvider>
    );
  };
};
export default SubscriptionsProvider;
